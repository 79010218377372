$gp-red: #ed1c24;
$gp-blue: #00aeef;
$gp-green: #39b54a;
$gp-yellow: #fbb040;
$gp-purple: #2e2192;

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.App-header {
  /* background-color: #282c34; */
  /* height: 64px; */
  padding: 5px 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  font-size: 26px;
  color: white;
  background-color: rgb(245, 245, 245);

  @media (min-width: 350px) {
    font-size: calc(32px + 2vmin);
  }
}

.App-logo {
  margin-left: 10px;
  width: 45px;
  height: 45px;
  position: relative;
  bottom: 4px;
}

.App-header__inner {
  width: 100%;
  max-width: 1024px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.App-link {
  text-decoration: none;
  font-weight: 600;
}

.container {
  width: 100%;
  margin: 10px auto 0;
  max-width: 1024px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: 700px) {
    margin-top: 50px;
  }
}

.main-image {
  width: 100%;
  max-width: 600px;
  filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.2));
}

.main-image--2 {
  left: calc(1024px - 600px);
  bottom: 0;
  margin-top: 30px;
  margin-bottom: 30px;
  @media (min-width: 934px) {
    position: relative;
    margin-top: 0;
    transform: translateY(-25%);
    z-index: -1;
  }
}

.contact-link {
  // text-decoration: none;
  // background-color: $gp-blue;
  // display: inline-block;
  // width: max-content;
  // padding: 10px;
  // color: white;
  // border-radius: 5px;
  color: $gp-blue;
}