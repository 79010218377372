* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Myriad, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(253, 253, 253);
  // width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Myriad;
  src: url(./fonts/regular.woff2) format("woff2"),
    url(./fonts/regular.woff) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: Myriad;
  src: url(./fonts/semibold.woff2) format("woff2"),
    url(./fonts/semibold.woff) format("woff");
  font-weight: 600;
}

$gp-red: #ed1c24;
$gp-blue: #00aeef;
$gp-green: #39b54a;
$gp-yellow: #fbb040;
$gp-purple: #2e2192;

.red {
  color: $gp-red;
}

.blue {
  color: $gp-blue;
}

.green {
  color: $gp-green;
}

.yellow {
  color: $gp-yellow;
}

.purple {
  color: $gp-purple;
}

.main-image-list {
  // list-style-type: none;
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 15px;
}

.main-text {
  font-size: 18px;
  margin-bottom: 15px;
  @media (min-width: 866px) {
    padding-left: 30px;
  }
}

.image-text-container {
  display: flex;
  max-width: 600px;
  flex-direction: column-reverse;
  align-items: flex-start;
  @media (min-width: 866px) {
    flex-direction: row;
    max-width: none;
  }
}

.contact-info {
  margin-top: auto;
  display: flex;
  padding: 20px;
  background-color: rgb(245, 245, 245);
}

.contact-info__list {
  max-width: 1024px;
  padding: 0 20px;
  list-style-type: none;
  font-size: 0.9rem;
  font-weight: 600;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  padding: 0;
  @media (min-width: 900px) {
    justify-content: space-between;
  }
  li {
    margin: 0 10px;
    width: 100%;
    @media (min-width: 350px) {
      width: auto;
    }
  }
}
